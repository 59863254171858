import Auth from '@/components/Auth.vue'
import List from '@/components/List.vue'
import Create from '@/components/Create.vue'
import Board from '@/components/Board.vue'
import Team from '@/components/Team.vue'
import Poker from '@/components/Poker.vue'

const routes = [
  { name: 'home', path: '/', component: List },
  { name: 'board', path: '/board', component: Board },
  { name: 'auth', path: '/auth', component: Auth },
  { name: 'create', path: '/create', component: Create },
  { name: 'team', path: '/team', component: Team },
  { name: 'poker', path: '/poker', component: Poker },
]

export default routes
