<template>
  <div class="columns">
    <div class="column">
      <h1 class="title">Results</h1>

      <div class="box">
        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="estimate in estimates" :key="estimate.id">
              <td>{{ estimate.data.name }}</td>
              <td>{{ state.revealed ? estimate.data.points : '*' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="level">
        <div class="level-left">
          <button class="button is-danger" @click.prevent="clear">Clear</button>
        </div>
        <div class="level-right">
          <button class="button" @click.prevent="changeRevealed(!state.revealed)">{{ state.revealed ? 'Hide' : 'Reveal' }}</button>
        </div>
      </div>
    </div>
    <div class="column is-8">
      <h1 class="title">Submit</h1>
      <div class="columns is-multiline">
        <div class="column is-3" v-for="(val, key) in ['?',1,2,3,5,8,13,21,34,55,69,'random']" :key="key">
          <article class="box has-text-centered is-size-1 has-text-weight-light pt-6 pb-6" @click.prevent="submit(val)">{{ val }}</article>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      estimates: [],
      state: {
        revealed: false
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$db.collection('estimates')
        .onSnapshot(querySnapshot => {
          this.estimates = querySnapshot.docs.map(doc => { return {'doc': doc.id, 'data': doc.data()}})
        })

      this.$db.collection('config').doc('0toLTo6K1kXelJAqrHtY')
        .onSnapshot(doc => {
          const config = doc.data()
          this.state.revealed = config.estimatesRevealed
        })
    },
    submit(points) {
      let docID = null

      for (const est of this.estimates) {
        if (est.data.name === this.$store.state.user.email) {
          docID = est.doc
          break;
        }
      }

      if (docID) {
        this.update(docID, points)
      } else {
        this.add(points)
      }
    },
    add(points) {
      const ref = this.$db.collection('estimates')

      ref.add({
        name: this.$store.state.user.email,
        points: points,
      }).then(() => {
        console.log('estimate created')
      }).catch((err) => {
        console.error(err)
      })
    },
    update(docID, points) {
      const ref = this.$db.collection('estimates').doc(docID)

      ref.update({
        name: this.$store.state.user.email,
        points: points,
      }).then(() => {
        console.log('estimate updated')
      }).catch((err) => {
        console.error(err)
      })
    },
    clear() {
      const batch = this.$db.batch()

      this.$db.collection('estimates').get().then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          batch.delete(this.$db.collection('estimates').doc(doc.id))
        })

        batch.commit().then(() => {
          this.state.revealed = false
          console.log('estimates cleared')
        }).catch(err => {
          console.error(err)
        })
      })
    },
    changeRevealed(state) {
      const ref = this.$db.collection('config').doc('0toLTo6K1kXelJAqrHtY')

      ref.update({
        estimatesRevealed: state
      }).then(() => {
        console.log('config updated')
      }).catch((err) => {
        console.error(err)
      })
    }
  }
}
</script>

<style type="text/css">
.box {
  cursor: pointer;
}
</style>
