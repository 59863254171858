<template>
Authentication
</template>

<script type="text/javascript">
import firebase from 'firebase/app'
import 'firebase/functions'
import config from '@/config.js'

export default {
  mounted() {
    const getAuthToken = firebase.app().functions('europe-west1').httpsCallable('getAuthToken');

    getAuthToken({
      code: this.$route.query.code,
      redirectURI: config.redirectURI,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        const token = result.data.token

        // Sign in with the returned token
        firebase.auth().signInWithCustomToken(token)
          .then((userCredential) => {
            const user = userCredential.user
            console.info('Logged in as', user.email)

            this.$router.push('/')
          })
          .catch((error) => {
            console.error(error)
          })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
</script>
