<template>
  <h1 class="title">
    {{ doc.name }}
    <a @click.prevent="openEditTitle()">
      <span class="material-icons">edit</span>
    </a>
  </h1>

  <div class="columns" v-if="doc">
    <div class="column" v-for="column, ckey in doc.columns" :key="ckey">
      <h3 class="title is-6">{{ column.name }}</h3>
      <div class="tickets">
        <div class="notification" :class="'is-' + column.color" v-for="ticket, key in column.tickets" :key="key">
          <div class="dropdown is-right is-hoverable">
            <div class="dropdown-trigger">
              <span class="icon">
                <span class="material-icons md-18">more_horiz</span>
              </span>
            </div>

            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a href="#" class="dropdown-item" @click.prevent="openEditTicket(ckey, key)">Edit</a>
                <a href="#" class="dropdown-item" @click.prevent="deleteTicket(ckey, key)">Delete</a>
              </div>
            </div>
          </div>

          <span class="ticket-text">{{ ticket.text }}</span>

          <span class="icon-text upvote-btn">
            <span>{{ ticket.upvotes.length }}</span>
            <span class="icon" @click.prevent="upvoteTicket(ckey, key)">
              <span class="material-icons">thumb_up</span>
            </span>
          </span>
        </div>

        <div class="field has-addons">
          <div class="control is-expanded">
            <textarea class="textarea is-small" placeholder="Add Item" v-model="state.field[ckey]"></textarea>
          </div>
          <div class="control">
            <a class="button" style="height: 100%;" @click.prevent="addTicket(ckey)">Add</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': state.editing.active}">
    <div class="modal-background" @click.prevent="state.editing.active = false"></div>
    <div class="modal-content">
      <div class="box">
        <h3 class="title">Edit</h3>
        <div class="field">
          <div class="control is-expanded">
            <textarea class="textarea" v-model="state.editing.ticket.text"></textarea>
          </div>
        </div>
        <div class="has-text-right">
          <a class="button is-primary" @click.prevent="editTicket()">Submit</a>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="state.editing.active = false"></button>
  </div>

  <div class="modal" :class="{'is-active': state.editingTitle.active}">
    <div class="modal-background" @click.prevent="state.editingTitle.active = false"></div>
    <div class="modal-content">
      <div class="box">
        <h3 class="title">Edit</h3>
        <div class="field">
          <div class="control is-expanded">
            <textarea class="textarea" v-model="doc.name"></textarea>
          </div>
        </div>
        <div class="has-text-right">
          <a class="button is-primary" @click.prevent="editTitle()">Submit</a>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="state.editingTitle.active = false"></button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      doc: {},
      state: {
        field: {},
        editing: {
          active: false,
          ticket: {},
        },
        editingTitle: {
          active: false,
        }
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      const ref = this.$db.collection('boards').doc(this.$route.query.id)

      ref.onSnapshot((doc) => {
        if (!doc.exists) {
          return console.error('document does not exist')
        }

        this.doc = doc.data()

        this.sortTickets()
      })
    },
    update() {
      const ref = this.$db.collection('boards').doc(this.$route.query.id)

      ref.update(this.doc).then(() => {
        console.log('document updated')
      }).catch((err) => {
        console.error(err)
      })
    },
    addTicket(colIndex) {
      const text = this.state.field[colIndex]

      if (text) {
        this.doc.columns[colIndex].tickets.push({
          text: text,
          author: this.$store.state.user.email,
          createdAt: (new Date()).getTime(),
          upvotes: [],
        })

        this.update()

        this.state.field[colIndex] = ''
      }
    },
    upvoteTicket(colIndex, tktIndex) {
      const tkt = this.doc.columns[colIndex].tickets[tktIndex]
      if (tkt.upvotes.includes(this.$store.state.user.email)) {
        return
      }

      tkt.upvotes.push(this.$store.state.user.email)
      this.update()
    },
    openEditTicket(colIndex, tktIndex) {
      this.state.editing.active = true
      this.state.editing.ticket = this.doc.columns[colIndex].tickets[tktIndex]
    },
    editTicket() {
      this.update()
      this.state.editing.active = false
      this.state.editing.ticket = {}
    },
    deleteTicket(colIndex, tktIndex) {
      this.doc.columns[colIndex].tickets.splice(tktIndex, 1)
      this.update()
    },
    sortTickets() {
      for (let i = 0; i < this.doc.columns.length; ++i) {
        this.doc.columns[i].tickets.sort((a, b) => {
          if (a.upvotes.length == b.upvotes.length) {
            return 0
          }

          return a.upvotes.length < b.upvotes.length ? 1 : -1;
        })
      }
    },
    openEditTitle() {
      this.state.editingTitle.active = true
    },
    editTitle() {
      this.update()
      this.state.editingTitle.active = false
    }
  }
}
</script>

<style type="text/css">
.tickets .notification {
  margin-bottom: 15px;
}
.notification .dropdown {
  position: absolute;
  top: .3rem;
  right: .5rem;
}
.notification .dropdown-trigger {
  cursor: pointer;
  height: 1rem;
}
.notification .upvote-btn {
  cursor: pointer;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}
.notification .upvote-btn .material-icons {
  font-size: 18px;
}
.notification .ticket-text {
  white-space: pre-wrap;
}
</style>
