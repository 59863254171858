<template>
  <div class="mb-4">
    <router-link to="/create" class="button is-primary">Create New Board</router-link>
  </div>

  <table class="table is-hoverable is-fullwidth">
    <thead>
      <tr>
        <th>Board Name</th>
        <th>Created By</th>
        <th>Created</th>
        <th class="has-text-right">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="board in docs" :key="board.id">
        <td><router-link :to="{ name: 'board', query: { 'id': board.id }}">{{ board.data().name }}</router-link></td>
        <td>{{ board.data().author }}</td>
        <td>{{ new Date(board.data().createdAt).toISOString() }}</td>
        <td>
          <div class="buttons is-right">
            <a @click.prevent="openDeleteModal(board.id)" class="button is-small is-danger">Delete</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <div class="mt-4 has-text-centered" v-if="state.next">
    <button class="button" @click.prevent="next">Load older</button>
  </div>


  <div class="modal" :class="{'is-active': state.deleting.active}">
    <div class="modal-background" @click.prevent="state.deleting.active = false"></div>
    <div class="modal-content">
      <div class="box">
        <h3 class="title">Are you sure?</h3>
        <div class="has-text-right">
          <a class="button is-danger" @click.prevent="deleteBoard()">Confirm</a>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="state.deleting.active = false"></button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      docs: [],
      state: {
        next: false,
        deleting: {
          active: false,
          board: null
        }
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      let startAfter = {}
      if (this.docs.length) {
        startAfter = this.docs[this.docs.length - 1]
      }

      const ref = this.$db.collection('boards').orderBy('createdAt', 'desc').startAfter(startAfter).limit(10)

      ref.get().then((querySnapshot) => {
        this.docs.push(...querySnapshot.docs)
        this.state.next = querySnapshot.size === 10
      })
    },
    openDeleteModal (id) {
      this.state.deleting.board = id
      this.state.deleting.active = true
    },
    deleteBoard () {
      if (!this.state.deleting.board) {
        return
      }

      const ref = this.$db.collection('boards').doc(this.state.deleting.board)

      ref.delete().then(() => {
        console.log('document deleted')
        this.state.deleting.active = false
        this.load()
      }).catch((err) => {
        console.error(err)
      })
    },
    next () {
      this.load()
    }
  }
}
</script>
