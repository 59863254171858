<template>
  <nav class="navbar has-shadow" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <img src="https://miracl.com/images/logos/miracl.svg" alt="MIRACL" width="112" height="28">
      </router-link>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" :class="{'is-active': state.menuIsActive}" @click.prevent="state.menuIsActive = !state.menuIsActive">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{'is-active': state.menuIsActive}">
      <div class="navbar-start">
        <router-link to="/" class="navbar-item">Retrospective Boards</router-link>
        <router-link to="/poker" class="navbar-item">Planning Poker</router-link>
        <!-- <router-link to="/team" class="navbar-item">My Teams</router-link> -->
      </div>

      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable" v-if="global.user">
          <a class="navbar-link is-small">{{ global.user.email }}</a>

          <div class="navbar-dropdown">
            <a class="navbar-item">Your Profile</a>
            <hr class="navbar-divider">
            <a class="navbar-item" @click.prevent="logout">Log Out</a>
          </div>
        </div>
        <div class="navbar-item" v-else>
          <div class="button is-primary" @click.prevent="login">Log In</div>
        </div>
      </div>
    </div>
  </nav>

  <section class="section">
    <router-view></router-view>
  </section>
</template>

<script>
import firebase from 'firebase/app'
import config from './config.js'

export default {
  name: 'App',
  data() {
    return {
      global: this.$store.state,
      state: {
        menuIsActive: false,
      },
    }
  },
  methods: {
    login() {
      window.location = 'https://api.mpin.io/authorize?client_id=' + config.clientID + '&redirect_uri=' + config.redirectURI + '&scope=openid+email&response_type=code'
    },
    logout() {
      firebase.auth().signOut().then(() => {
        console.log('Sign-out successful')
        this.$store.setUser(null)
        this.login()
      }).catch((error) => {
        console.error(error)
      })
    }
  }
}
</script>
