import { reactive } from 'vue'

const store = {
  state: reactive({
    user: null
  }),
  setUser(user) {
    this.state.user = user
  }
}

export default store
