<template>
  <table class="table is-hoverable is-fullwidth">
    <thead>
      <tr>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in users" :key="user.uid">
        <td>{{ user.data().email }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  data() {
    return {
      users: []
    }
  },
  methods: {
    load() {
    },
    create() {
    },
  }
}
</script>
