import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import App from './App.vue'
import routes from './routes.js'
import store from './store.js'

const app = createApp(App)

// Routing
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

app.use(router)

// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyA_3mziB3EoC8X3SgmVPfgQyRApSuc2r6U",
  authDomain: "miracl-retro.firebaseapp.com",
  projectId: "miracl-retro",
  storageBucket: "miracl-retro.appspot.com",
  messagingSenderId: "854271939488",
  appId: "1:854271939488:web:663ec1a60b0ab7a8ba8ac5"
})

app.config.globalProperties.$db = firebase.firestore()

app.config.globalProperties.$store = store
firebase.auth().onAuthStateChanged((user) => {
  store.setUser(user)
})

app.mount('#app')
