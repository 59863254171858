<template>
<div class="field">
  <label class="label">Name</label>
  <div class="control">
    <input type="text" v-model="doc.name" class="input">
  </div>
</div>

<p class="content">
  <b>Columns</b>
  <ul>
    <li v-for="col, key in doc.columns" :key="key">
      <span class="tag" :class="'is-'+col.color">{{ col.name }}</span>
    </li>
  </ul>
</p>

<a class="button is-primary" @click.prevent="create">Create</a>
</template>

<script>
export default {
  data() {
    return {
      doc: {
        name: "",
        createdAt: (new Date()).getTime(),
        author: this.$store.state.user.email,
        columns: [
          {
            name: "Went Well",
            color: "success",
            tickets: [],
          },
          {
            name: "To Improve",
            color: "danger",
            tickets: [],
          },
          {
            name: "Action Items",
            color: "info",
            tickets: [],
          },
        ],
      },
    }
  },
  methods: {
    create() {
      if (!this.doc.name.length) {
        return
      }

      const ref = this.$db.collection('boards')

      ref.add(this.doc).then(() => {
        console.log('document created')
        this.$router.push('/')
      }).catch((err) => {
        console.error(err)
      })
    },
  }
}
</script>
